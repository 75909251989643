import React, { useState, useEffect, useRef } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../Components/Services/apiServices";
import { BrowserView, MobileView } from "react-device-detect";
import Skeleton from "react-loading-skeleton";
import Pagination from "react-js-pagination";
import ReactTooltip from 'react-tooltip';

let apiServices = new ApiService();
function Order() {
    const didMountRef = useRef(true)
    const history = useNavigate()
    const [OrderList, setOrderList] = useState([])
    const [skeletonshow, setskeletonshow] = useState(false)
    const [currentpage, setcurrentpage] = useState(1)
    const [perPageRecords, setperPagerecords] = useState();
    const [totalRecords, setTotalRecords] = useState(10);
    const [pagePagination , setPagePagination] = useState("")
    useEffect(() => {
        if (didMountRef.current) {
            getOrderData(1)
            // setskeletonshow(true)
            // apiServices.getuserOrdergetrequest().then((response) => {
            //     console.log(response, "responsehjk")
            //     if (response?.data?.status == "success") {
            //         setskeletonshow(false)
            //         setOrderList(response?.data?.data?.data)
            //     }
            //     else {
            //         setskeletonshow(false)
            //     }
            // })
        }
        didMountRef.current = false;
    }, [])
    const getIndianTime = (createdat) => {
        const timestamp = createdat;
        const date = new Date(timestamp);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        let formattedHours = hours % 12;
        formattedHours = formattedHours === 0 ? 12 : formattedHours;
        const period = hours < 12 ? 'AM' : 'PM';
        const formattedTime = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds} ${period}`;
        return formattedTime
    }

    const getOrderData = (pagenumber) => {
        console.log(pagenumber)
        setskeletonshow(true)
        console.log(pagenumber, "pagenumber")
       
        
        apiServices.getuserOrdergetrequest(pagenumber).then(res => {
            if (res.data?.status == 'success') {
                setskeletonshow(false)
                setOrderList(res.data.data?.data)
                setcurrentpage(res?.data?.data?.current_page)
                setTotalRecords(res?.data?.data?.total)
                setperPagerecords(res?.data?.data?.per_page)
                setPagePagination(res?.data?.data?.last_page)

    
            }
        })
    }

    return (
        <>
            <BrowserView>
                <Header />
                <div className="subheader">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h1>My Orders</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                                    <Breadcrumb.Item active href="/order">Orders</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="sec-pad-sm">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                {skeletonshow == false ? <>
                                    {OrderList && OrderList.length > 0 && OrderList?.map((items, index) => {
                                        return (<>
                                            <div className="morderbox" key={index + 1} 
                                            // onClick={() => { history(`/order-details/${items?.trans_id}`) }}
                                            >
                                                <div className="row">
                                                    <div className="col-lg-5">
                                                    <div className="morderbox-fund">
                                                    <div className="tx-14 fw600">{items?.trans_mf_name ? items?.trans_mf_name : "Order name"}</div>
                                                    <ul>
                                                        <li>ID: {items?.trans_order_no}</li>
                                                    </ul>
                                                </div>
                                                    </div>

                                                    <div className="col-lg-3">
                                                    <div className="fundname-price line18">
                                                    <div className="tx-16 fw600">{items?.trans_type}</div>
                                                {items?.trans_amount  ?<div className="tx-12 fw600">₹{items?.trans_amount}</div>:""}
                                                {/* {items?.trans_switch_unit && items?.trans_switch_redeem_type == "UNITS" ?<div className="tx-16 fw600">{items?.trans_switch_unit}</div>:""} */}
                                                <div className="tx-12 fw600">{items?.trans_switch_redeem_type == "UNITS"  ?items?.trans_switch_unit:""} {items?.trans_switch_redeem_type}</div>
                                                   
                                                </div>
                                                    </div>
                                                    <div className="col-lg-2"><div className="fundstatus">
                                                    <span className={`badge ${items?.trans_status == 1 ? "bg-warning-subtle text-warning" : items?.trans_status == 0 ? "bg-primary-subtle text-primary" : items?.trans_status == 2 ? "bg-danger-subtle text-danger" : items?.trans_status == 3 ? "bg-success-subtle text-success" : ""} `}>{items?.trans_status == 1 ? "in process" : items?.trans_status == 0 ? "Transaction Pending" : items?.trans_status == 2 ? "Transaction Failed" : items?.trans_status == 3 ? "Transaction Success" : ""}</span>
{((items.trans_payment_mode === "UPI"&& items?.trans_status !== 3) || (items.trans_payment_mode === "Nodal" && items?.trans_status !== 3) || (items.trans_payment_mode === "Direct" && items?.trans_status !== 3)) ?
  <div style={{ marginTop: "7px" }} onClick={() => { history('/transaction-status/' + items.trans_id) }}>
    <small style={{font:"20px"}}>Check Payment Status</small>
  </div>
: ''}
                                                     
                                                        </div></div>
                                                    <div className="col-lg-2">
                                                              
                                                <div className="fundname-date dflex align-items-center justify-content-end">
                                                    <div className="line18">
                                                        <div className="tx-14 fw600">
                                                            {new Date(items?.created_at).getDate() + "/" + (new Date(items?.created_at).getMonth() + 1) + "/" + new Date(items?.created_at).getFullYear()}
                                                        </div>
                                                        <div className="tx-12">
                                                            {items?.created_at ? getIndianTime(items?.created_at) : ""}
                                                            {/* {new Date(items?.created_at).getHours() + ":" + new Date(items?.created_at).getMinutes()} */}
                                                        </div>
                                                    </div>
                                                    <ReactTooltip />
                                                    <p data-tip={items?.trans_order_status_text}><i className="icon-info22 ms-4"></i></p>

                                                    {/* <i className="icon-arrow-right6 ms-4"></i> */}
                                                </div>
                                                    </div>
                                                   
                                                </div>
                                                
                                                
                                          
                                            </div>
                                        </>)
                                    })}
                                </> : <>
                                <div className="col-lg-12">
    <div className="morderbox">
        <div className="row">
            <div className="col-lg-5">
                <div className="morderbox-fund">
                    <div className="tx-14 fw600"><Skeleton width="100%" height="15px" /></div>
                    <ul>
                        <li><Skeleton width="95px" height="12px" /></li>
                    </ul>
                </div>
            </div>
            <div className="col-lg-3">
                <div className="fundname-price line18">
                    <div className="tx-16 fw600"><Skeleton width="30px" height="13px" /></div>
                    <div className="tx-12"><Skeleton width="65px" height="12px" /></div>
                </div>
            </div>
            <div className="col-lg-2">
                <div className="fundstatus"><span ><Skeleton width="65px" height="15px" /></span></div>
            </div>
            <div className="col-lg-2">
                <div className="fundname-date dflex align-items-center justify-content-end">
                    <div className="line18">
                        <div className="tx-14 fw600"><Skeleton width="65px" height="15px" /></div>
                        <div className="tx-12"><Skeleton width="65px" height="12px" /></div>
                    </div>

                    <i className="icon-info22 ms-4"></i>
                </div>
            </div>
        </div>
    </div>
    <div className="morderbox">
        <div className="row">
            <div className="col-lg-5">
                <div className="morderbox-fund">
                    <div className="tx-14 fw600"><Skeleton width="100%" height="15px" /></div>
                    <ul>
                    <li><Skeleton width="95px" height="12px" /></li>
                    </ul>
                </div>
            </div>
            <div className="col-lg-3">
                <div className="fundname-price line18">
                    <div className="tx-16 fw600"><Skeleton width="30px" height="13px" /></div>
                    <div className="tx-12"><Skeleton width="65px" height="12px" /></div>
                </div>
            </div>
            <div className="col-lg-2">
                <div className="fundstatus"><span ><Skeleton width="65px" height="15px" /></span></div>
            </div>
            <div className="col-lg-2">
                <div className="fundname-date dflex align-items-center justify-content-end">
                    <div className="line18">
                    <div className="tx-14 fw600"><Skeleton width="65px" height="15px" /></div>
                        <div className="tx-12"><Skeleton width="65px" height="12px" /></div>
                    </div>
                    <i className="icon-info22 ms-4"></i>
                </div>
            </div>
        </div>
    </div>
</div>
                                </>}
                                {OrderList.length == 0 && skeletonshow == false ? <>
                                    <div className="noimg">
                                        <img src="/img/notransactions.png" />
                                        <h6>No Order Found</h6>
                                        <a href="/mutual-funds" className="btn btn-outline-primary btn-md mt-3">Start Investment</a>
                                    </div>
                                </> : ""}

                                {OrderList.length >0 && pagePagination !== 1   ?   <>

<div className="d-flex justify-content-end">
    <Pagination
        activePage={currentpage}
        itemsCountPerPage={perPageRecords}
        totalItemsCount={totalRecords}
        onChange={(pageNumber) => getOrderData(pageNumber)}
        pageRangeDisplayed={5}
        itemClass="page-item"
        linkClass="page-link"
        hideDisabled={true} 
    />
</div>

</> : ""}

                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </BrowserView>
            <MobileView>
            <header className="mheader">
                    <div className="container">
                        <div className="mheader-left">
                            <div className="dflex align-items-center" >
                                <i className="d-icon-arrow-left tx-22" onClick={() => { history("/my-account") }}></i>
                                <div className="ms-3">
                                    <h6 className="mb-0">My Orders</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="mobile-main">
                {skeletonshow == false ? <>
                    {OrderList && OrderList?.map((items, index) => {
                        return (<>
                            <div className="mobileorder"
                            //  onClick={() => { history(`/order-details/${items?.trans_id}`) }}
                             >
                                <div className="mobileorderleft">
                                    <div className="tx-12 fw600 line18 mb-2">{items?.trans_mf_name ? items?.trans_mf_name : "Order name"}</div>
                                    <ul>
                                        <li>{items?.trans_order_no}</li>
                                        <li>{items?.trans_type}</li>
                                       
                                       <li>{items?.trans_switch_redeem_type == "UNITS"  ?items?.trans_switch_unit:""} {items?.trans_switch_redeem_type}</li> 
                                    </ul>
                                    {((items.trans_payment_mode === "UPI"&& items?.trans_status !== 3) || (items.trans_payment_mode === "Nodal" && items?.trans_status !== 3) || (items.trans_payment_mode === "Direct" && items?.trans_status !== 3)) ?
  <div style={{ marginTop: "7px" }} onClick={() => { history('/transaction-status/' + items.trans_id) }}>
    <small style={{font:"20px"}}>Check Payment Status</small>
  </div>
: ''}
                                </div>
                                <div className="mobileorderright">
                                {items?.trans_amount ?  <div className="tx-16 fw600">₹{items?.trans_amount}</div>:""}
                                    <div className="tx-12 tx-gray">
                                        {new Date(items?.created_at).getDate() + "/" + (new Date(items?.created_at).getMonth() + 1) + "/" + new Date(items?.created_at).getFullYear()} @  {items?.created_at ? getIndianTime(items?.created_at) : ""}
                                    </div>
                                    <span className={`badge ${items?.trans_status == 1 ? "bg-warning-subtle text-warning" : items?.trans_status == 0 ? "bg-primary-subtle text-primary" : items?.trans_status == 2 ? "bg-danger-subtle text-danger" : ""} `}>{items?.trans_status == 1 ? "in process" : items?.trans_status == 0 ? "Pending" : items?.trans_status == 2 ? "Transaction Failed" : ""}</span>
                                   {items?.trans_order_status_text ?<small className="tx-10">{items?.trans_order_status_text}</small>:""}
                                </div>
                            </div>
                        </>)
                    })}
                        </> : <>

    <div className="mobileorder">
        <div className="mobileorderleft">
            <div className="tx-12 fw600 line18 mb-2"><Skeleton width="100%" height="15px" /></div>
            <ul>
                <li><Skeleton width="65px" height="15px" /></li>
                <li><Skeleton width="65px" height="15px" /></li>
            </ul>
        </div>
        <div className="mobileorderright">
            <div className="tx-12 tx-gray"><Skeleton width="95px" height="15px" /></div>
            <span ><Skeleton width="65px" height="15px" /></span>
        </div>
    </div>
    <div className="mobileorder">
        <div className="mobileorderleft">
            <div className="tx-12 fw600 line18 mb-2"><Skeleton width="100%" height="15px" /></div>
            <ul>
                <li><Skeleton width="65px" height="15px" /></li>
                <li><Skeleton width="65px" height="15px" /></li>
            </ul>
        </div>
        <div className="mobileorderright">
            <div className="tx-12 tx-gray"><Skeleton width="95px" height="15px" /></div>
            <span ><Skeleton width="65px" height="15px" /></span>
        </div>
    </div>



                        </>}


                    {OrderList.length == 0 && skeletonshow == false ? <>
                        <div className="noimg">
                            <img src="/img/notransactions.png" />
                            <h6>No Order Found</h6>
                        </div>
                    </> : ""}

                    {OrderList.length >0 && pagePagination !== 1   ?   <>

<div className="d-flex justify-content-end">
    <Pagination
        activePage={currentpage}
        itemsCountPerPage={perPageRecords}
        totalItemsCount={totalRecords}
        onChange={(pageNumber) => getOrderData(pageNumber)}
        pageRangeDisplayed={5}
        itemClass="page-item"
        linkClass="page-link"
        hideDisabled={true} 
    />
</div>

</> : ""}
                </div>
            </MobileView>
        </>
    )
}
export default Order